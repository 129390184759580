<template>
  <div
       :class="{ 'shrinkreport-content': drawer && !isMobile }"
       style="margin-top: 65px; padding: 5px">
    <Breadcrumbs style="display:inline-block;" />
    <v-container fluid style="position:relative;">
      <v-row v-if="!news" class="news-container">
        <v-col offset-xl="3" xl="6" offset-lg="2" lg="8" offset-sm="2" sm="8" cols="12">
          ...
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="ml-4">
          <h2>{{ news.title }}</h2>
          <h5 class="mb-2">{{ this.moment(news.createdAt).format('YYYY-MM-DD HH:mm') }}</h5>
          <div v-html="news.description"></div>
          
            <v-btn @click="$router.push('/news')" rounded color="primary">
              « {{ $t('report.return') }}
            </v-btn>
          
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import authHeader from "@/services/auth-header";
import constants from "@/constants";
import { parseError } from "@/utils/utils"
const moment = require('moment');

const axios = require('axios');
var API_URL = constants.API_URL + "news/";

export default {
  name: "News",
  components: {
    Breadcrumbs
  },
  watch: {
    $route() {
      this.reloadNews();
    }
  },
  data: () => {
    return {
      news: null
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    })
  },
  methods: {
    ...mapMutations([
      'showErrorMessage'
    ]),
    ...mapActions({
    }),
    moment,
    async reloadNews() {
      try {
        let newsId = this.$route.params.id;

        let url = API_URL + newsId + "?language=" + this.$i18n.locale;

        let res = await axios.get(url, { headers: authHeader() });
        if (res && res.data) {
          this.news = res.data.news;
        }
      }
      catch (err) {
        this.showErrorMessage(parseError(err));
        this.$router.push('/news');
      }
    }
  },
  async mounted() {
    await this.reloadNews();

    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("general.news"), href: "/news" },
      {
        text: this.news.title
      },
    ]);
  },
};
</script>

<style scoped>
@media (min-width: 1000px) {
  .news-container {
    max-height: calc(100vh - 200px);
  }
}
</style>